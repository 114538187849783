* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0;
}

.layOut_wrapper > .ant-layout {
  position: relative;
}
.layOut_wrapper > .ant-layout .dark_mode {
  background-color: #262626;
}
.layOut_wrapper > .ant-layout .ant-layout-sider .ant-layout-sider-children .logo {
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
}
.layOut_wrapper > .ant-layout .ant-layout-sider .ant-layout-sider-children .logo .sideBar-icon-lg {
  display: block;
}
.layOut_wrapper > .ant-layout .ant-layout-sider .ant-layout-sider-children .logo .sideBar-icon-sm {
  display: none;
}
.layOut_wrapper > .ant-layout .ant-layout-sider-collapsed .ant-layout-sider-children .logo .sideBar-icon-lg {
  display: none;
}
.layOut_wrapper > .ant-layout .ant-layout-sider-collapsed .ant-layout-sider-children .logo .sideBar-icon-sm {
  display: block;
}
.layOut_wrapper > .ant-layout .ant-layout-sider {
  overflow-x: hidden;
  position: fixed;
  min-height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  border-radius: 0 10px 10px 0 !important;
}
.layOut_wrapper > .ant-layout .ant-layout-sider .overlay_cover_sidevbar {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  display: none;
}
.layOut_wrapper > .ant-layout .ant-space .ant-space-item {
  min-height: 100%;
}
.layOut_wrapper > .ant-layout .site-layout {
  padding-left: 90px;
  padding-right: 20px;
}
.layOut_wrapper > .ant-layout .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.layOut_wrapper > .ant-layout .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 calc(50% - 15px) !important;
}

@media screen and (max-width: 992px) {
  .layOut_wrapper > .ant-layout .ant-layout-sider-collapsed {
    -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 60px !important;
            flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
  }
  .layOut_wrapper > .ant-layout .ant-layout-sider .overlay_cover_sidevbar {
    display: block;
  }
  .layOut_wrapper > .ant-layout .site-layout {
    padding-left: 60px;
    padding-right: 5px;
  }
  .layOut_wrapper > .ant-layout .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
  .layOut_wrapper > .ant-layout .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 calc(50% - 10px) !important;
  }
}
.dashboard .ant-card {
  border-radius: 5px;
}
.dashboard .ant-card .ant-card-head {
  border-bottom: none;
}
.dashboard .ant-card .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  color: aqua;
}
.dashboard .ant-card .ant-card-body {
  position: relative;
}
.dashboard .ant-card .ant-card-body .ant-avatar {
  border: 2px solid black;
}
.dashboard .ant-card .ant-card-body p {
  padding-top: 10px;
  margin: 0;
  text-align: center;
}
.dashboard .ant-card .ant-card-body .status_staft {
  background-color: aquamarine;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 3px;
  position: absolute;
  top: 2%;
  padding: 5px 5px;
}
.dashboard .statusGroup {
  border-radius: 10px;
}
.dashboard .statusGroup {
  padding: 10px;
}
.dashboard .statusGroup .panel_header_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dashboard .statusGroup .panel_header_content .panel_header_content_title h6 {
  margin-bottom: 0;
}
.dashboard .statusGroup .panel_header_content .panel_header_content_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.dashboard .statusGroup .panel_header_content .panel_header_content_icon i:first-child {
  color: "rgb(237, 125, 49)";
}
.dashboard .statusGroup .panel_header_content .panel_header_content_icon i:last-child {
  color: "rgb(112, 172, 72)";
}

.login-bg {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2))), url("../../assets/imgs/bgr__rain1.jpg");
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../assets/imgs/bgr__rain1.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  font-family: "Nunito", sans-serif;
}
.login-bg .block-element {
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
  top: 50vh;
  left: 50vw;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60%;
}
.login-bg .block-element h4 {
  color: #fff;
  font-size: 28px;
}
.login-bg .block-element p {
  color: #fff;
}
.login-bg .block-element label {
  color: #fff;
  margin-right: 10px;
}
.login-bg .block-element .fpw-banner {
  margin: auto;
}
.login-bg .block-element .introduce-banner {
  top: 0;
  right: 0;
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #fff;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login-bg .block-element .introduce-banner h2 {
  color: #096481;
  font-weight: 700;
}
.login-bg .block-element .introduce-banner p {
  color: #096481;
}
.login-bg .block-element .introduce-banner .introduce-banner-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login-bg .block-element .introduce-banner.move {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.login-bg .block-element .ant-space {
  gap: 0 !important;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.login-bg .block-element .ant-space .ant-space-item {
  width: 100%;
}
.login-bg .block-element p.fpw:hover {
  color: #2988ab;
}
.login-bg .block-element .btn-login:hover,
.login-bg .block-element .btn-fake:hover {
  background-color: #096481;
  border-color: #096481;
  color: #fff;
}
.login-bg .block-element .btn-fake,
.login-bg .block-element .btn-login {
  background-color: #2988ab;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
  font-size: 15px;
  border: solid 1px #2988ab;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.login-bg .block-element .btn-fake {
  cursor: pointer;
}
.login-bg .ant-btn:focus {
  background: #2988ab;
}
.login-bg .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  content: "";
}
.login-bg .ant-checkbox-wrapper span:last-child {
  color: #fff;
}
.login-bg .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2988ab;
  border-color: #2988ab;
}
.login-bg .hide {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: none;
}
.login-bg .introduce-banner-sm {
  padding: 10px;
  margin-bottom: 25px;
  text-align: center;
  display: none;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 15px;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media screen and (max-width: 576px) {
  .login-bg .block-element {
    width: 90%;
  }
  .login-bg .block-element .ant-space {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .login-bg .block-element .introduce-banner {
    display: none;
  }
  .login-bg .block-element .introduce-banner-sm {
    display: block;
  }
  .hide {
    display: none;
  }
}
.mp_ex_form {
  font-size: 0.9rem;
}
.mp_ex_form .c {
  text-align: center;
}
.mp_ex_form .b {
  font-weight: 500;
}
.mp_ex_form .f {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mp_ex_form .f-h {
  line-height: 40px;
  background-color: #c7842a;
  text-align: center;
  font-weight: 600;
}
.mp_ex_form .container {
  background-color: #fff;
  border: 1px solid #e6e9ed;
  border-radius: 1px;
  padding: 10px;
}
.mp_ex_form .border-none {
  border: 0px !important;
  margin: 1px;
}
.mp_ex_form .p-10-20 {
  padding: 10px 20px;
}
.mp_ex_form .border-none {
  border: none;
}

.mainZone {
  position: relative;
  height: 262px;
  width: 143px;
}
.mainZone .injuryZone .text-danger-glow {
  color: #ff4141;
  font-size: 7px;
  text-shadow: 0 0 20px #f00, 0 0 30px #f00, 0 0 40px #f00, 0 0 50px #f00, 0 0 60px #f00, 0 0 70px #f00;
}
.mainZone .injuryZone .blink {
  -webkit-animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
          animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}
@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.mainZone .injuryZone .Left_Foot {
  position: absolute;
  bottom: 22px;
  left: 58px;
}
.mainZone .injuryZone .Left_Ankle {
  position: absolute;
  bottom: 32px;
  left: 58px;
}
.mainZone .injuryZone .Left_Knee {
  position: absolute;
  bottom: 80px;
  left: 55px;
}
.mainZone .injuryZone .Left_Shin {
  position: absolute;
  bottom: 50px;
  left: 57px;
}
.mainZone .injuryZone .Left_Caft {
  position: absolute;
  bottom: 57px;
  left: 55px;
}
.mainZone .injuryZone .Left_Thign {
  position: absolute;
  bottom: 115px;
  left: 52px;
}
.mainZone .injuryZone .Left_Buttocks {
  position: absolute;
  bottom: 135px;
  left: 55px;
}
.mainZone .injuryZone .Left_Waist,
.mainZone .injuryZone .Left_Hip {
  position: absolute;
  bottom: 150px;
  left: 52px;
}
.mainZone .injuryZone .Left_Groin {
  position: absolute;
  bottom: 125px;
  left: 60px;
}
.mainZone .injuryZone .Left_Stomach {
  position: absolute;
  bottom: 155px;
  left: 60px;
}
.mainZone .injuryZone .Left_Ribs {
  position: absolute;
  bottom: 168px;
  left: 55px;
}
.mainZone .injuryZone .Left_Chest {
  position: absolute;
  bottom: 185px;
  left: 55px;
}
.mainZone .injuryZone .Left_Back {
  position: absolute;
  bottom: 170px;
  left: 62px;
}
.mainZone .injuryZone .Left_Shoulder {
  position: absolute;
  bottom: 200px;
  left: 48px;
}
.mainZone .injuryZone .Left_Neck {
  position: absolute;
  bottom: 205px;
  left: 60px;
}
.mainZone .injuryZone .Left_Hand {
  position: absolute;
  bottom: 145px;
  left: 43px;
}
.mainZone .injuryZone .Left_Wrist {
  position: absolute;
  bottom: 146px;
  left: 40px;
}
.mainZone .injuryZone .Left_Forearm {
  position: absolute;
  bottom: 155px;
  left: 35px;
}
.mainZone .injuryZone .Left_Elbow {
  position: absolute;
  bottom: 171px;
  left: 30px;
}
.mainZone .injuryZone .Left_Bicep {
  position: absolute;
  bottom: 182px;
  left: 37px;
}
.mainZone .injuryZone .Left_Head,
.mainZone .injuryZone .Left_Forehead,
.mainZone .injuryZone .Right_Head,
.mainZone .injuryZone .Right_Forehead {
  position: absolute;
  bottom: 228px;
  left: 64px;
}
.mainZone .injuryZone .Left_Ears {
  position: absolute;
  bottom: 220px;
  left: 55px;
}
.mainZone .injuryZone .Left_Eyes {
  position: absolute;
  bottom: 220px;
  left: 62px;
}
.mainZone .injuryZone .Right_Foot {
  position: absolute;
  bottom: 22px;
  left: 75px;
}
.mainZone .injuryZone .Right_Ankle {
  position: absolute;
  bottom: 33px;
  left: 75px;
}
.mainZone .injuryZone .Right_Knee {
  position: absolute;
  bottom: 80px;
  left: 72px;
}
.mainZone .injuryZone .Right_Shin {
  position: absolute;
  bottom: 53px;
  left: 72px;
}
.mainZone .injuryZone .Right_Caft {
  position: absolute;
  bottom: 57px;
  left: 77px;
}
.mainZone .injuryZone .Right_Thign {
  position: absolute;
  bottom: 115px;
  left: 72px;
}
.mainZone .injuryZone .Right_Buttocks {
  position: absolute;
  bottom: 137px;
  left: 75px;
}
.mainZone .injuryZone .Right_Waist,
.mainZone .injuryZone .Right_Hip {
  position: absolute;
  bottom: 150px;
  left: 77px;
}
.mainZone .injuryZone .Right_Groin {
  position: absolute;
  bottom: 125px;
  left: 70px;
}
.mainZone .injuryZone .Right_Stomach {
  position: absolute;
  bottom: 155px;
  left: 70px;
}
.mainZone .injuryZone .Right_Ribs {
  position: absolute;
  bottom: 168px;
  left: 75px;
}
.mainZone .injuryZone .Right_Chest {
  position: absolute;
  bottom: 185px;
  left: 73px;
}
.mainZone .injuryZone .Right_Back {
  position: absolute;
  bottom: 170px;
  left: 68px;
}
.mainZone .injuryZone .Right_Shoulder {
  position: absolute;
  bottom: 200px;
  left: 83px;
}
.mainZone .injuryZone .Right_Neck {
  position: absolute;
  bottom: 205px;
  left: 70px;
}
.mainZone .injuryZone .Right_Hand {
  position: absolute;
  bottom: 118px;
  left: 88px;
}
.mainZone .injuryZone .Right_Wrist {
  position: absolute;
  bottom: 125px;
  left: 88px;
}
.mainZone .injuryZone .Right_Forearm {
  position: absolute;
  bottom: 140px;
  left: 88px;
}
.mainZone .injuryZone .Right_Elbow {
  position: absolute;
  bottom: 157px;
  left: 90px;
}
.mainZone .injuryZone .Right_Bicep {
  position: absolute;
  bottom: 178px;
  left: 88px;
}
.mainZone .injuryZone .Right_Ears {
  position: absolute;
  bottom: 220px;
  left: 73px;
}
.mainZone .injuryZone .Right_Eyes {
  position: absolute;
  bottom: 220px;
  left: 68px;
}
.mainZone .injuryZone .Left_Nose,
.mainZone .injuryZone .Right_Nose {
  position: absolute;
  bottom: 217px;
  left: 65px;
}
.mainZone .injuryZone .Left_Mount,
.mainZone .injuryZone .Right_Mount {
  position: absolute;
  bottom: 212px;
  left: 65px;
}
.mainZone .injuryZone .Left_Chin,
.mainZone .injuryZone .Right_Chin {
  position: absolute;
  bottom: 210px;
  left: 65px;
}

.day-off-calendar .calendar-header {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 30px 30px 20px 30px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.day-off-calendar .ant-picker-calendar {
  padding: 10px 15px;
  border-radius: 10px;
}
.day-off-calendar .ant-picker-calendar .events li {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: bold;
  text-align: center;
  margin: auto;
}
.day-off-calendar .ant-picker-calendar .events li .ant-badge-status-text:hover {
  color: #2988ab;
}

@media screen and (max-width: 576px) {
  .day-off-calendar .ant-badge-status-text {
    display: none;
  }
}
.modal-add-dkp .ant-space .ant-space-item {
  width: 100%;
}

.dataTable {
  font-size: 12px;
}
.dataTable ::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 3px;
}
.dataTable ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
}
.dataTable .headerTableStyle {
  font-weight: bold;
  font-size: 1.2em;
}
.dataTable .react-bootstrap-table {
  margin-bottom: 5px;
}
.dataTable .react-bootstrap-table table {
  table-layout: auto;
  overflow: scroll;
}
.dataTable .react-bootstrap-table table thead th {
  white-space: nowrap;
}
.dataTable .react-bootstrap-table table thead th .ant-space {
  gap: 0 !important;
  position: relative;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.dataTable .react-bootstrap-table table thead th .ant-space .ant-space-item:last-child {
  position: absolute;
  top: 40%;
}
.dataTable .react-bs-table-sizePerPage-dropdown #pageDropDown {
  width: 60px;
  height: 30px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: transparent;
  color: black;
}
.dataTable .react-bs-table-sizePerPage-dropdown #pageDropDown:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dataTable .react-bs-table-sizePerPage-dropdown .dropdown-menu {
  width: 55px;
  min-width: 55px;
}
.dataTable .react-bs-table-sizePerPage-dropdown .dropdown-menu > li {
  padding: 0;
}
.dataTable .react-bs-table-sizePerPage-dropdown .dropdown-menu > li a {
  text-align: center;
  display: block;
  padding: 3px 5px;
  clear: both;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
}
.dataTable .pagination {
  margin-bottom: 0;
}
.dataTable .pagination li[title="previous page"] a:after,
.dataTable .pagination li[title="next page"] a:after {
  font: var(--fa-font-solid);
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  background-color: #fff;
}
.dataTable .pagination li[title="previous page"] a:hover:after,
.dataTable .pagination li[title="next page"] a:hover:after {
  background-color: #e9ecef;
}
.dataTable .pagination li[title="next page"] a:after {
  content: "\f054";
}
.dataTable .pagination li[title="previous page"] a:after {
  content: "\f053";
}
.dataTable .pagination .page-item {
  height: 30px;
  width: 30px;
  border-radius: 5px;
}
.dataTable .pagination .page-item .page-link {
  line-height: 0.8;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ant-select-dropdown-menu {
  max-height: none !important;
  overflow-y: visible !important;
}

.bg-form {
  font-family: "Nunito", sans-serif;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 30px;
  margin-top: 1rem;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
          box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
.bg-form .bg-form-header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
}
.bg-form .bg-form-header h4 {
  font-weight: bold;
}
.bg-form .bg-form-header .ant-btn-primary {
  border-color: #001529;
  background: #001529;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 6px 0 rgba(10, 207, 151, 0.5);
          box-shadow: 0 2px 6px 0 rgba(10, 207, 151, 0.5);
}
.bg-form .bg-form-header .ant-btn-primary:hover {
  background-color: black;
}
.bg-form .ant-form-item .ant-col-3 {
  text-align: left;
}
.bg-form .body-part-effect > .ant-col:first-child,
.bg-form .body-part-effect > .ant-col:last-child {
  display: grid;
}
.bg-form label[for~=__location] {
  text-align: left;
}

.ant-col-24.ant-form-item-label > label::before {
  display: none !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none !important;
}

.dashboard .ant-card .ant-card-body p {
  padding: 0;
  margin: 0;
}
.dashboard .statusEmployee {
  padding-right: 1%;
  border-radius: 10px;
}
.dashboard .statusEmployee .contentStatus {
  float: left;
  width: 100%;
  -webkit-transform: skew(10deg);
          transform: skew(10deg);
  position: static;
}
.dashboard .statusEmployee .contentStatus .contentStatus_child {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
.dashboard .statusEmployee .contentStatus .contentStatus_child .contentStatus_child_num {
  font-size: 18px;
  line-height: 1;
}
.dashboard .statusEmployee .contentStatus .contentStatus_child .contentStatus_child_text {
  color: #000;
  font-size: 12px;
}
.dashboard .statusEmployee .overlayStatus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 95px;
  height: 70px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: skew(-10deg);
          transform: skew(-10deg);
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.dashboard .statusEmployee .overlayStatus .contentStatus svg {
  width: 45px;
  height: 45px;
}
.dashboard .statusGroup {
  margin-top: 10px;
}
.dashboard .statusGroup .ant-card .ant-card-body {
  padding: 10px 5px;
}
.dashboard .ant-carousel .slick-slider {
  border-radius: 10px;
}
.dashboard .ant-carousel .slick-slider .slick-list {
  border-radius: 10px;
}
.dashboard .event_content {
  padding: 2rem;
  position: relative;
}
.dashboard .event_content .event_content_overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
}

@media screen and (max-width: 576px) {
  .dashboard .statusEmployee .overlayStatus {
    width: 95px;
    height: 50px;
  }
  .dashboard .statusEmployee .overlayStatus .contentStatus svg {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 376px) {
  .dashboard .statusEmployee .overlayStatus {
    min-width: 40px;
    width: 70px;
    height: 45px;
  }
  .dashboard .statusEmployee .overlayStatus .contentStatus svg {
    width: 20px;
    height: 20px;
  }
  .dashboard .statusEmployee .overlayStatus .contentStatus .contentStatus_child .contentStatus_child_num {
    font-size: 12px;
  }
  .dashboard .statusEmployee .overlayStatus .contentStatus .contentStatus_child .contentStatus_child_text {
    font-size: 10px;
  }
}
.header {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.header .fa-solid {
  color: #e7ebf0;
}
.header .ant-menu-submenu-arrow {
  display: none;
}
.header #demo1:before,
.header #demo2:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 15px;
  height: 14px;
  width: 14px;
  border: 1px solid #e7ebf0;
  background-color: white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  z-index: 101;
  border-bottom: none;
  border-right: none;
}
.header .menu_acc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  width: 70px;
  height: 50px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border-radius: 50%;
}
.header .menu_acc:hover .menu_notificate,
.header .menu_acc .menu_acc_list {
  color: white;
}
.header .menu_notificate ul {
  padding-bottom: 1rem;
}
.header .menu_notificate ul li a p {
  margin-bottom: 0;
}
.header .menu_acc_list,
.header .menu_notificate {
  position: absolute;
  top: calc(100% + 14px);
  right: 17%;
  z-index: 100;
  background-color: white;
  width: 200px;
  -webkit-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
          box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  border-radius: 5px;
  border: 1px solid #e7ebf0;
}
.header .menu_acc_list ul,
.header .menu_notificate ul {
  margin: 0;
}
.header .menu_acc_list ul li,
.header .menu_notificate ul li {
  padding: 7px 20px;
  cursor: pointer;
}
.header .menu_acc_list ul li i,
.header .menu_notificate ul li i {
  margin-right: 10px;
  color: gray;
}
.header .menu_acc_list ul li:hover,
.header .menu_notificate ul li:hover {
  background-color: gray;
  color: white;
}
.header .menu_acc_list ul li:hover i,
.header .menu_notificate ul li:hover i {
  color: white;
}
.header .header-left {
  margin-right: 10px;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
          box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
.header .header-left .ant-menu-title-content {
  color: #fff !important;
}
.header .header-left ul {
  border-right: none;
  border-radius: 5px;
}
.header .header-left ul .ant-menu-submenu-active {
  background-color: #096481;
}
.header .header-left ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 5px;
  color: #6c757d;
  background-color: #001529;
  height: 100%;
}
.header .header-left ul li .ant-menu-submenu-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.header .header-left .ant-menu-submenu:after {
  border-bottom: none !important;
}
.header .header-left .menu-header-left {
  font-size: 20px;
  font-weight: bold;
}
.header .header-right {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  background-color: #001529;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
          box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
.header .header-right .ant-avatar {
  -webkit-box-shadow: 2px 4px 11px 2px rgba(154, 161, 171, 0.5);
          box-shadow: 2px 4px 11px 2px rgba(154, 161, 171, 0.5);
}
.header .header-right .collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}
.header .header-right h4 {
  font-size: 2rem;
  margin-left: 5px;
  margin-bottom: 0;
  color: #fff;
}
.header .header-right::before {
  position: absolute;
  content: "";
  min-height: 100%;
  width: 5px;
  background-color: #096481;
  padding: 0;
  top: 0;
  left: 0;
  border-radius: 10px 0 0 10px;
}
.header .header-right .bg-bell {
  height: 45px;
  width: 45px;
  background-color: whitesmoke;
  border-radius: 50%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 2px 4px 11px 2px rgba(154, 161, 171, 0.5);
          box-shadow: 2px 4px 11px 2px rgba(154, 161, 171, 0.5);
  color: #096481;
  border: 1px solid rgba(154, 161, 171, 0.5);
}
.header .header-right .bg-bell svg {
  width: 1.5rem;
  height: 1.5rem;
}
.header .header-right .bg-bell:hover {
  background-color: #2988ab;
  color: #d0d2d4;
  -webkit-transition: all 0.3;
  transition: all 0.3;
}
.header .header-right .bg-bell:hover .mode-container i {
  color: white;
}
.header .header-right .menu_acc .ant-avatar {
  height: 45px;
  width: 45px;
}
.header .header-right .menu_acc:hover i {
  color: #096481;
}

.ant-menu-submenu > .ant-menu {
  border-radius: 10px !important;
}

.ant-menu-submenu-popup {
  padding-top: 10px !important;
  left: 90px !important;
  top: 50px !important;
}

@media screen and (max-width: 576px) {
  .ant-menu-submenu-popup {
    padding-top: 10px !important;
    left: 90px !important;
    top: 50px !important;
    width: 200px !important;
  }
  .ant-menu-submenu-title:active {
    background: transparent !important;
  }
  .header .menu_notificate {
    right: 0;
  }
  .header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .header .header-right {
    padding: 0 5px;
  }
  .header .header-right h4 {
    font-size: 1rem;
  }
  .header .header-right .bg-bell {
    height: 25px;
    width: 25px;
  }
  .header .header-right .bg-bell svg {
    width: 1rem;
    height: 1rem;
  }
  .header .header-right .menu_acc {
    width: 40px;
  }
  .header .header-right .menu_acc .ant-avatar {
    height: 25px;
    width: 25px;
  }
  .header .header-left {
    width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .header .header-left ul {
    height: 35px;
  }
  .header .header-left ul li {
    width: 100%;
  }
  .header .header-left .menu-header-left {
    font-size: 12px;
  }
}
.footer {
  color: #d0d2d4;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15) !important;
          box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15) !important;
  border-radius: 10px;
  background-color: #001529 !important;
}
.footer h5,
.footer p {
  color: #d0d2d4;
  margin-bottom: 1rem;
}
.footer .ant-col li a {
  text-decoration: none;
  line-height: 2;
  color: #d0d2d4;
}
.footer .ant-col li a:hover {
  color: #2988ab;
}
.footer .footer-contact li {
  color: #d0d2d4;
  line-height: 2;
}
.footer .footer-social li {
  margin-right: 45px;
  position: relative;
}
.footer .footer-social li a i {
  font-size: 20px;
}
.footer .footer-social li a i::after {
  position: absolute;
  content: "";
  width: 38px;
  height: 38px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid white;
  bottom: 50%;
  right: 50%;
  -webkit-transform: translate(50%, 44%);
          transform: translate(50%, 44%);
}
.footer .footer-social li:hover i::after {
  border: 2px solid #2988ab;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 24px 5px !important;
  }
  .footer .ant-row:last-child {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .footer .ant-row:last-child .ant-col:last-child {
    margin-bottom: 10px;
  }
  .footer .ant-row:last-child .ant-col:first-child {
    max-width: 100% !important;
  }
  .footer .footer-social li:last-child {
    margin-right: 0;
  }
}
.create_new_btn,
.back-btn,
.search-btn,
.add_btn {
  background-color: #2988ab !important;
  border-radius: 5px !important;
  height: 35px !important;
  margin-bottom: 0;
  margin-left: 5px;
}
.create_new_btn i, .create_new_btn span,
.back-btn i,
.back-btn span,
.search-btn i,
.search-btn span,
.add_btn i,
.add_btn span {
  color: white;
  font-weight: bold;
}
.create_new_btn:hover,
.back-btn:hover,
.search-btn:hover,
.add_btn:hover {
  background-color: #096481 !important;
}

#search-bar-0 {
  height: 35px !important;
}